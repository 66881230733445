@font-face {
  font-family: "KAIVALYA";
  src: url("./font/KAIVALYADEMO.woff2") format("woff2"),
    url("./font/KAIVALYADEMO.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #ff8600;
  border: 2px solid #ffffff;
  padding: 20px;
  border-radius: 50px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.close-btn {
  border: 2px solid #ffffff;
  width: 100px;
  height: 80px;
  cursor: pointer;
  background-color: white;
  border-radius: 50px;
  font-family: KAIVALYA;
  color: #ff8600;
  font-size: 20px;
}

.modal-content {
  color: #ffffff;
  font-family: Impact, sans-serif;
  font-size: 30px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
