@font-face {
  font-family: "KAIVALYA";
  src: url("./font/KAIVALYADEMO.woff2") format("woff2"),
    url("./font/KAIVALYADEMO.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.launch {
  color: white;
  font-family: Impact, "Arial Narrow Bold", sans-serif;
  font-size: 17px;
}

.launch-container {
  background-color: silver;
  border: 5px solid #ff8600;
  width: 70%;
}

.video-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.video-container iframe {
  width: 50%; /* Make the iframe take up 100% of the container's width */
  height: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hero {
  height: 1020px;
  background-color: #140f4a;
  border-top: 15px solid #ff8600;
  border-bottom: 15px solid #ff8600;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.title {
  height: 80px;
  width: 350px;
  padding-top: 50px;
}

.heroLogo {
  height: 350px;
  width: 350px;
}

.hero > a {
  color: #ff8600;
  font-family: KAIVALYA;
  font-size: 30px;
  padding-bottom: 10px;
}

.buttonSection {
  height: 300px;
  width: 100%;
  flex-direction: column;
}

.launch-con {
  display: flex;
  justify-content: center;
}

.buyButton {
  height: 65px;
  width: 80%;
  background-color: rgb(137, 225, 137);
  margin-top: 3px;
  margin-bottom: 3px;
  border: 2px solid #ffffff;
  font-size: 45px;
  font-weight: bold;
  color: white;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  transition: background-color 0.3s ease;
  border-radius: 20px;
}
.twitterButton {
  height: 65px;
  width: 80%;
  background-color: rgb(8, 15, 89);
  margin-top: 3px;
  margin-bottom: 3px;
  border: 2px solid #ffffff;
  font-size: 45px;
  max-height: 100px; /* Set maximum height for the text */
  overflow: hidden;
  font-weight: bold;
  color: white;
  font-family: KAIVALYA;
  transition: background-color 0.3s ease;
  border-radius: 20px;
}

.telegramButton {
  height: 65px;
  width: 80%;
  background-color: rgb(55, 72, 253);
  margin-top: 3px;
  margin-bottom: 3px;
  border: 2px solid #ffffff;
  font-size: 40px;
  font-weight: bold;
  color: white;
  font-family: KAIVALYA;
  transition: background-color 0.3s ease;
  border-radius: 20px;
}

.chartsButton {
  height: 65px;
  width: 80%;
  background-color: rgb(236, 123, 92);
  margin-top: 3px;
  margin-bottom: 3px;
  border: 2px solid #ffffff;
  font-size: 40px;
  font-weight: bold;
  color: white;
  font-family: KAIVALYA;
  transition: background-color 0.3s ease;
  border-radius: 20px;
}

.YTButton {
  height: 65px;
  width: 80%;
  background-color: red;
  margin-top: 3px;
  margin-bottom: 3px;
  border: 2px solid #ffffff;
  font-size: 40px;
  font-weight: bold;
  color: white;
  font-family: KAIVALYA;
  transition: background-color 0.3s ease;
  border-radius: 20px;
}

.buyButton:hover {
  background-color: #388e3c; /* Darker green on hover */
}
.twitterButton:hover {
  background-color: rgb(5, 9, 59); /* Darker green on hover */
}
.telegramButton:hover {
  background-color: rgb(38, 50, 185); /* Darker green on hover */
}
.chartsButton:hover {
  background-color: rgb(149, 76, 56); /* Darker green on hover */
}

.caption {
  color: #ff8600;
  font-family: KAIVALYA;
  font-size: 15px;
  padding-bottom: 10px;
}

.contact-address {
  color: #ff8600;
  font-family: KAIVALYA;
  font-size: 15px;
}

.divid {
  margin-bottom: 20px;
}

.welcum-container {
  background-color: white;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.memes-container {
  background-color: white;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.memes-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.welcum-title {
  color: #140f4a;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 40px;
  margin-top: 10px;
  text-align: center;
}

.welcum-caption {
  color: #140f4a;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  margin-top: 10px;
  text-align: left;
}

.selfie {
  height: 350px;
  width: 350px;
}

.mims {
  width: calc(33.33% - 10px);
  margin: 5px;
  border: 1px solid #ccc;
}

.about-container {
  background-color: #140f4a;
  height: 500px;
  border-top: 15px solid #ff8600;
  border-bottom: 15px solid #ff8600;
  display: flex;
  flex-direction: column;
}

.about-title {
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 40px;
  margin-top: 10px;
  text-align: left;
  margin-left: 10px;
}

.about-text {
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 30px;
  margin-top: 10px;
  text-align: left;
  margin-left: 10px;
}

.roadmap-container {
  background-color: white;
  height: 500px;
  display: flex;
  flex-direction: column;
}

.rodmep-image {
  height: 450px;
  width: 100px;
}

.map-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.rodmep-item {
  color: #140f4a;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  text-align: left;
  margin-bottom: 30px;
}

.divider {
  margin-bottom: 100px;
}

.footer {
  background-color: #ff8600;
  color: white;
  text-align: center;

  left: 0;
  bottom: 0;
  width: 100%;
}

.footer-text {
  font-size: 14px;
}
